import React from 'react';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { MedicalCard } from '../../MedicalCard/MedicalCard';
import useTmCard from '../../../hooks/useTmCard';
import useSnackbar from '../../../hooks/useSnackbar';
import { useAuthContext } from '../../../contexts/authContext';
import { Severity } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 300,
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textDecoration: 'underline',
    fontSize: theme.typography.fontSize.toFixed(24),
    fontWeight: 'bold',
  },
  textField: {
    marginTop: theme.spacing(8),
    height: 30,
    maxWidth: 300,
  },
  paper: { padding: '16px', display: 'flex', flexDirection: 'column', height: '100%' },
  colorTypography: {
    paddingRight: 30,
    fontWeight: 600,
    marginTop: 50,
  },
}));

export function CardSettings() {
  const { cardSettings, applyChanges } = useTmCard();
  const { user } = useAuthContext();
  const toast = useSnackbar();
  const classes = useStyles();

  const handleLogoChange = async (logo: string) => {
    if (!user || user.status === 'new') {
      toast('You need to complete your profile first', Severity.error);
      return;
    }
    await applyChanges({ ...cardSettings, carrier: { ...cardSettings.carrier, logo } });
    toast('Carrier logo updated successfully', Severity.success);
  };

  return (
    <>
      <Typography className={classes.title}>Card Overview</Typography>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 40 }}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Front
          </Typography>
          <MedicalCard
            bgColor={cardSettings.card.primaryBgColor}
            isGradient={cardSettings.card.isGradient}
            gradientColorFrom={cardSettings.card.primaryBgColor}
            gradientColorTo={cardSettings.card.colorGradientTo}
            fontColor={cardSettings.card.fontColor}
          >
            <MedicalCard.Front
              onLogoChange={handleLogoChange}
              carrierLogo={cardSettings?.carrier?.logo}
              carrierName={cardSettings?.carrier?.name}
              memberName={cardSettings.member.fullName}
              policyNumber={cardSettings.member.policyNumber}
              memberId="123456789"
              dateOfBirth="01/01/2000"
              effectiveDate={new Date().toLocaleDateString()}
            />
          </MedicalCard>
        </div>
        <div>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Back
          </Typography>
          <MedicalCard
            bgColor={cardSettings.card.primaryBgColor}
            isGradient={cardSettings.card.isGradient}
            gradientColorFrom={cardSettings.card.primaryBgColor}
            gradientColorTo={cardSettings.card.colorGradientTo}
            fontColor={cardSettings.card.fontColor}
          >
            <MedicalCard.Back
              additionalText={cardSettings.card.additionalText}
              carrierLocalPhone={cardSettings?.carrier?.phoneNumber}
              carrierWebsite={cardSettings?.carrier?.website}
              carrierEmail={cardSettings?.carrier?.email}
              carrierPhoneNumber={cardSettings?.carrier?.medicalPhoneNumber}
            />
          </MedicalCard>
        </div>
      </div>
    </>
  );
}
