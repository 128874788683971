import React from 'react';

import AccountPanel from '../components/AccountPanel/AccountPanel';
import classes from './Layout.module.scss';

export default function WelcomePage() {
  return (
    <div className={classes.welcomeContainer}>
      <AccountPanel />
    </div>
  );
}
