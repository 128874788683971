import React, { useState, useContext } from 'react';
import { Button, TextField, FormControlLabel, Checkbox, CircularProgress, makeStyles } from '@material-ui/core';
import { Context as AuthContext } from '../../../contexts/authContext';
import { CheckBoxTermOfUse } from '../../Controllers/CheckBoxTermOfUse/CheckBoxTermOfUse';
import { Register2FAModal } from './Register2FAModal';
import { UserRole } from '../../../types';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
}));

export default function RegisterFormBusiness() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const { register, errors, isLoading, clearState } = useContext(AuthContext);
  const [disable, setDisable] = useState(true);
  const [open2FA, setOpen2FA] = useState(false);

  const onRegister = async () => {
    if (password !== repassword) {
      setIsPasswordMatch(false);
      return;
    }
    if (register) {
      setOpen2FA(true);
    }
  };

  const onFocus = () => {
    if (clearState) {
      clearState();
    }
  };

  const on2FAOptionChooseHandler = async (twoFactorMethod: string) => {
    if (password !== repassword) {
      setIsPasswordMatch(false);
      return;
    }
    if (!email || !password || !companyName || !fullName || !phone) {
      return;
    }
    if (register) {
      await register(email, password, UserRole.admin, companyName, fullName, phone, twoFactorMethod);
      setOpen2FA(false);
    }
  };

  return (
    <>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Admin Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={(errors && errors.email?.length > 0) || undefined}
          helperText={errors && errors.email?.join(', ')}
          onFocus={onFocus}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="companyName"
          label="Company Name"
          name="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="fullName"
          label="Full Name"
          name="fullName"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          required
          id="phone"
          label="Phone Number"
          name="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          error={(errors && errors.password?.length > 0) || undefined}
          helperText={errors && errors.password?.join(', ')}
          onFocus={onFocus}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="repassword"
          label="Renter Password"
          type="password"
          id="repassword"
          autoComplete="current-password"
          value={repassword}
          onChange={(e) => setRePassword(e.target.value)}
          error={!isPasswordMatch}
          helperText={!isPasswordMatch ? 'Passwords do not match' : undefined}
          onFocus={() => setIsPasswordMatch(true)}
        />
        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
        <CheckBoxTermOfUse onAgreeChange={(isAgree) => setDisable(isAgree)} />
        <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={onRegister} disabled={isLoading || disable}>
          {!isLoading ? 'Register' : <CircularProgress color="inherit" />}
        </Button>
      </form>
      <Register2FAModal open={open2FA} handleClose={on2FAOptionChooseHandler} email={email} phone={phone} />
    </>
  );
}
