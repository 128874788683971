import React, { useState, useContext, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Context as AuthContext } from "../../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
}));

export default function ResetPasswordForm() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [userParams, setUserParams] = useState({ email: "", token: "" });

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { isLoading, errors, clearState, resetPassword } = useContext(
    AuthContext
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    const email = decodeURIComponent(url.searchParams.get("email") || "");
    const token = decodeURIComponent(url.searchParams.get("token") || "");
    setUserParams({ email, token });
  }, []);

  const onClick = async () => {
    if (password !== rePassword) {
      setIsPasswordMatch(false);
      return;
    }
    if (!isPasswordChanged) {
      if (resetPassword) {
        const successResponse = await resetPassword(
          userParams.email,
          password,
          rePassword,
          userParams.token
        );
        if (successResponse) {
          setIsPasswordChanged(true);
        }
      }
    }
  };
  const onFocus = () => {
    if (clearState) {
      clearState();
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h2" variant="body1">
        Reset Password
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="New Password"
          name="password"
          autoComplete="password"
          type="password"
          autoFocus
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={
            (errors && errors.password && errors.password.length > 0) ||
            undefined
          }
          helperText={errors && errors.password && errors.password[0]}
          onFocus={onFocus}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="Re Enter Password"
          name="repassword"
          autoComplete="password"
          type="password"
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
          error={!isPasswordMatch}
          helperText={!isPasswordMatch ? "Passwords do not match" : undefined}
          onFocus={() => setIsPasswordMatch(true)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onClick}
          disabled={isLoading || isPasswordChanged}
        >
          {!isLoading ? (
            "Change Password"
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Button>
        {errors && errors.serverError && (
          <Typography color="error">{errors.serverError}</Typography>
        )}
        {isPasswordChanged && (
          <Typography color="primary">
            Password Changed Successfully. Please sign in.
          </Typography>
        )}
        <Grid container>
          <Grid item xs>
            <Link href="/" variant="body2">
              Sign in
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.teramedics.com/">
              Teramedics
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </form>
    </div>
  );
}
