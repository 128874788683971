import React, { useReducer } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Fade } from '@material-ui/core';
import { Severity } from '../types';

interface ContextState {
  show: (message: string, severity: Severity) => void;
}

export const Context = React.createContext<ContextState>({ show: () => 0 });

type SnackbarProps = {
  id: number;
  open: boolean;
  message: string;
  severity: Severity;
  onClose: () => void;
  onSnackClose: () => void;
};

type Action = { type: 'add'; payload: SnackbarProps } | { type: 'remove'; payload: number };

const INIT_STATE: SnackbarProps[] = [];

const reducer = (state = INIT_STATE, action: Action): SnackbarProps[] => {
  switch (action.type) {
    case 'add':
      return [...state, action.payload];
    case 'remove':
      return [...state.filter((snackbar) => snackbar.id !== action.payload)];
    default:
      return state;
  }
};

export const Provider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const show = (message: string, severity: Severity) => {
    const id = new Date().getTime();
    const snackbar = {
      message,
      severity,
      onClose: () => {
        dispatch({ type: 'remove', payload: id });
      },
      onSnackClose: () => {
        dispatch({ type: 'remove', payload: id });
      },
      open: true,
      id,
    };
    dispatch({ type: 'add', payload: snackbar });
  };

  return (
    <Context.Provider value={{ show }}>
      {children}
      {state.map((snackbar, index) => (
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={snackbar.onSnackClose}
          TransitionComponent={Fade}
          key={snackbar.id}
          style={{ bottom: 24 + 50 * index }}
        >
          <MuiAlert elevation={6} variant="filled" onClose={snackbar.onClose} severity={snackbar.severity}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      ))}
    </Context.Provider>
  );
};
