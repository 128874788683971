import React, { useState } from 'react';
import { Modal, Backdrop, Fade, Button, RadioGroup, FormControlLabel, Radio, Typography, makeStyles, Paper, Slide } from '@material-ui/core';
// import { TwoFactorFormVerification } from '../2FA/TwoFactorFormVerification';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '500px',
    margin: '0 auto',
    overflow: 'hidden',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #e3e3e3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    borderRadius: '8px',
    width: '500px',
    height: '300px',
    overflow: 'hidden',
    position: 'relative',
  },
  slidePaper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    height: '100%',
  },
  continueButton: {
    marginTop: theme.spacing(2),
  },
}));

type Register2FAModalProps = {
  open: boolean;
  handleClose: (twoFactorMethod: string) => void;
  email: string;
  phone: string;
};

export const Register2FAModal = ({ open, handleClose, email, phone }: Register2FAModalProps) => {
  const classes = useStyles();
  const [selected2FA, setSelected2FA] = useState('');
  const [showVerification, setShowVerification] = useState(false);

  const handle2FAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected2FA(event.target.value);
  };

  const handleContinue = () => {
    if (selected2FA) {
      //   setShowVerification(true);
      handleClose(selected2FA);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => handleClose(selected2FA)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Slide direction="down" in={!showVerification} timeout={500} mountOnEnter unmountOnExit>
            <div>
              <Typography variant="h6" id="2fa-modal-title">
                Choose Your 2FA Method
              </Typography>
              <RadioGroup aria-label="2fa-method" name="2fa-method" value={selected2FA} onChange={handle2FAChange}>
                <FormControlLabel value="email" control={<Radio />} label={`Email (${email})`} />
                <FormControlLabel value="sms" control={<Radio />} label={`SMS (${phone})`} />
                <FormControlLabel
                  value="authenticator"
                  control={<Radio />}
                  label="Authenticator (Google or Microsoft) - Setup on profile page after register"
                  disabled
                />
              </RadioGroup>
              <Button variant="contained" color="primary" className={classes.continueButton} onClick={handleContinue} disabled={!selected2FA}>
                Continue
              </Button>
            </div>
          </Slide>
          {/* <Slide direction="up" in={showVerification} timeout={{ enter: 400, appear: 500 }} mountOnEnter unmountOnExit>
            <Paper className={classes.slidePaper}>
              <Typography variant="caption">Please verify the 2FA code</Typography>
              <TwoFactorFormVerification onVerifySuccess={onVerifyHandler} />
            </Paper>
          </Slide> */}
        </div>
      </Fade>
    </Modal>
  );
};
