import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, Container, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MemberFilter, MemberFilterBy } from '../../types';

export type SearchMemberProps = {
  onSearchClick: (memberFilter: MemberFilter) => void;
};

const SearchMember = ({ onSearchClick }: SearchMemberProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState<MemberFilterBy>('memberUniqueId');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchTypeChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setSearchType(e.target.value as MemberFilterBy);
  };

  const handleSearchClick = async () => {
    onSearchClick({ searchBy: searchType, value: searchValue });
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '20px' }}>
      <FormControl variant="outlined" style={{ marginRight: '10px', minWidth: '150px' }}>
        <InputLabel id="search-type-label">Search Type</InputLabel>
        <Select labelId="search-type-label" value={searchType} onChange={handleSearchTypeChange} label="Search Type">
          <MenuItem value="memberUniqueId">Member Unique id</MenuItem>
          <MenuItem value="id">Member ID</MenuItem>
          <MenuItem value="policyId">Policy ID</MenuItem>
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ width: '300px' }}
      />
    </Container>
  );
};

export { SearchMember };
