import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    const st: any = this.state;
    if (st.hasError) {
      return <h1>Something went wrong. Please contact support</h1>;
    }

    return this.props.children;
  }
}
