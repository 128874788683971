import { isAxiosError } from 'axios';
import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { TmUser, UserLanguage, UserRole } from '../types';
import { logger } from './logger';

const SERVER_ERROR = {
  serverError: 'Something went wrong please try again...',
};

type authResponse = {
  statusCode: number;
  statusText: string;
  data: any;
  errors: string[] | null;
};

interface ValidationError {
  email: string[];
  password: string[];
  gender?: string[];
  dateOfBirth?: string[];
  serverError?: string;
}

interface AuthData {
  dateOfBirth: Date;
  email: string;
  fullName: string;
  gender: string;
  id: string;
  isAdult: boolean;
  lang: string;
  role: string;
  token: string;
  type: string;
}

interface AuthData2FARequired {
  message: string;
}

const processInputValidation = (email: string, password: string) => {
  const errors: ValidationError = {
    email: [],
    password: [],
  };
  const isEmailValid = email.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  if (!isEmailValid) {
    errors.email = ['Invalid email'];
  }

  if (password.length < 8) {
    errors.password.push('password must have at least 8 characters');
  }
  const isPasswordValid = (password.match(/[a-z]/g) || password.match(/[A-Z]/g) || password.match(/[0-9]/g)) && password.length >= 8;
  if (!isPasswordValid) {
    errors.password.push('Invalid password');
  }

  return errors;
};

export const signInToDAS = async (email: string, password: string, code?: string): Promise<authResponse> => {
  const errors = processInputValidation(email, password);
  if (errors.email.length > 0 || errors.password.length) {
    throw errors;
  }
  try {
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    const { data, status, statusText } = await client.post<AuthData | AuthData2FARequired>('users/signin', { email, password, code });
    // check if data is 2FA required

    if ((data as AuthData2FARequired).message === 'waiting for 2FA') {
      return {
        data: null,
        statusCode: status,
        statusText,
        errors: ['2FA required'],
      };
    }

    localStorage.setItem('token', (data as AuthData).token);
    return {
      data: data as AuthData,
      statusCode: status,
      statusText,
      errors: null,
    };
  } catch (error) {
    const e = error as any; // TODO: add guard
    return {
      data: null,
      statusCode: e.code,
      statusText: e.message,
      errors: [e.message],
    };
  }
};

export const signUpToDAS = async (
  email: string,
  password: string,
  role: UserRole,
  companyName: string,
  fullName: string,
  phone: string,
  twoFactorMethod = 'email'
): Promise<authResponse> => {
  const errors = processInputValidation(email, password);

  if (errors.email.length > 0 || errors.password.length || errors.gender || errors.dateOfBirth) {
    throw errors;
  }

  try {
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    const { data, status, statusText } = await client.post<AuthData>('users/signup', {
      email,
      password,
      fullName,
      role,
      phone,
      companyName,
      twoFactorMethod,
    });
    localStorage.setItem('token', data.token);

    return {
      data,
      statusCode: status,
      statusText,
      errors: null,
    };
  } catch (error) {
    if (isAxiosError(error)) {
      logger.error('Error while signing up', { error });
    }
    throw SERVER_ERROR;
  }
};

export const createDasUserWorker = async (email: string, password: string, lang = UserLanguage.en): Promise<authResponse> => {
  const errors = processInputValidation(email, password);
  if (errors.email.length || errors.password.length) {
    throw new Error(errors.email.concat(errors.password).reduce((a: string, b: string) => `${a} ${b} , `, ''));
  }
  try {
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    const { data, status, statusText } = await client.post<AuthData>('users/admin/workers', {
      email,
      password,
      role: UserRole.worker,
      lang,
    });

    return {
      data,
      statusCode: status,
      statusText,
      errors: null,
    };
  } catch (error) {
    const e = error as any; // TODO: add guard
    throw new Error(e.response.data.errors.map((e: any) => e.message).reduce((a: string, b: string) => `${a} ${b} , `, ''));
  }
};

export const deleteDasUserWorker = async (email: string) => {
  try {
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    const { data, status, statusText } = await client.delete('users/admin/workers', { params: { email } });
    return {
      data,
      statusCode: status,
      statusText,
      errors: null,
    };
  } catch (error) {
    const e = error as any; // TODO: add guard
    throw new Error(e.response.data.errors.map((e: any) => e.message).reduce((a: string, b: string) => `${a} ${b} , `, ''));
  }
};

export const signout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  try {
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    await client.post('users/signout');
  } catch (error) {
    // TODO: log error while trying to log out (remove cookie)
  }
};

export const getCurrentUser = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data } = await client.get<TmUser>('users/currentuser');

  return { ...data, token };
};

export const sendEmailForReset = async (email: string): Promise<authResponse> => {
  const isEmailValid = email.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  if (!isEmailValid) {
    const err = { email: ['Invalid email'] };
    throw err;
  }
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data, status, statusText } = await client.post('users/forgotpassword', { email });
  return {
    data,
    statusCode: status,
    statusText,
    errors: null,
  };
};

export const resetPasswordDAS = async (email: string, password: string, rePassword: string, dbCode: string) => {
  const errors = processInputValidation(email, password);
  if (errors.email.length > 0 || errors.password.length) {
    throw errors;
  }
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data, status, statusText } = await client.post('users/resetpassword', { email, password, rePassword, token: dbCode });
  return {
    data,
    statusCode: status,
    statusText,
    errors: null,
  };
};

export const generateNewPAT = async (userIdentifier: string, permissions: string, tokenName: string) => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const res = await client.post<{ token: string }>('v1/pat', { userId: userIdentifier, permissions, tokenName });
  return res.data.token;
};

export const deletePAT = async (tokenName: string) => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  await client.delete('v1/pat', { data: { tokenName } });
};

export const verify2FA = async (code: string) => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data } = await client.post('users/verify-2fa', { code });
  return data;
};

export const generate2FA = async () => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data } = await client.post('users/generate-2fa', {});
  return data.qrCode;
};

export const update2FAMethod = async (method: string) => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  await client.put('users/update-2fa-method', { method });
};
