import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography, Link, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Context as AuthContext } from '../../../contexts/authContext';
import { TwoFactorFormVerification } from './TwoFactorFormVerification';
import { update2FAMethod, generate2FA } from '../../../services/authService';
import useSnackbar from '../../../hooks/useSnackbar';
import { Severity } from '../../../types';
import { logger } from '../../../services/logger';

export const TwoFactorAuth = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const [twoFactorData, setTwoFactorData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('sms');
  const toast = useSnackbar();

  useEffect(() => {
    if (user?.twoFactorEnabled && user?.twoFactorMethod) {
      setSelectedMethod(user?.twoFactorMethod);
    }
  }, [user]);

  const onEnable2FA = async () => {
    setIsLoading(true);
    const qr = await generate2FA();
    setTwoFactorData(qr);
    setIsLoading(false);
  };

  const on2FASuccess = async () => {
    try {
      await update2FAMethod(selectedMethod);
      if (refreshUser) {
        await refreshUser();
      }
      setTwoFactorData('');
      toast('2-Factor Authentication method changed successfully', Severity.success);
    } catch (error) {
      logger.error('Failed to update 2FA method', { error: error as Error });
      toast('Unknown error', Severity.error);
    }
    setIsLoading(false);
  };

  const on2FASelectionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
    await update2FAMethod(event.target.value);
    toast(`2-Factor Authentication method changed to ${event.target.value}`, Severity.success);
  };

  return (
    <Box width="900px" padding="20px" border="1px solid #ddd" borderRadius="8px" boxShadow="0 2px 4px rgba(0,0,0,0.1)">
      <Typography variant="h5" gutterBottom>
        Enhance Your Security with 2-Factor Authentication
      </Typography>
      <Typography variant="body1" paragraph>
        Enable 2-factor authentication to add an extra layer of security to your account.
      </Typography>
      <Typography variant="body1" paragraph>
        We recommend using one of the following authenticator apps or methods:
      </Typography>
      <RadioGroup value={selectedMethod} onChange={on2FASelectionChange}>
        <FormControlLabel value="sms" control={<Radio />} label="SMS" />
        <FormControlLabel value="email" control={<Radio />} label="Email" />
        <FormControlLabel value="authenticator" control={<Radio />} label="Authenticator App" />
      </RadioGroup>
      {selectedMethod === 'authenticator' && (
        <>
          <Box display="flex" alignItems="center" marginBottom="20px" marginTop="20px">
            <Link
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener"
              style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}
            >
              <img src="/google-authenticator-2.svg" width={40} style={{ marginRight: '8px' }} />
              Google Authenticator
            </Link>
            <Link
              href="https://www.microsoft.com/en-us/account/authenticator"
              target="_blank"
              rel="noopener"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img src="/microsoft-authenticator.svg" width={40} style={{ marginRight: '8px' }} />
              Microsoft Authenticator
            </Link>
          </Box>
          <Button variant="contained" color="primary" onClick={onEnable2FA} disabled={isLoading || twoFactorData !== ''}>
            Scan QR
          </Button>
          {twoFactorData && (
            <div style={{ textAlign: 'center', marginTop: 40 }}>
              <Typography>Scan the QR code on your authentication app</Typography>
              <img src={twoFactorData} alt="2FA_QR" />
              <Typography style={{ marginBottom: 30 }}>Once done scanning please verify</Typography>
              <TwoFactorFormVerification onVerifySuccess={on2FASuccess} />
            </div>
          )}
        </>
      )}
    </Box>
  );
};
