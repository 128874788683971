import axios from 'axios';
import { ApiEndPoint } from '../Enums/ApiEndPoint';

type ClientConfig = {
  baseURL: string;
};

export const getClient = ({ baseURL }: ClientConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    return axios.create({ baseURL, headers: { Authorization: `Bearer ${token}` } });
  } else {
    return axios.create({ baseURL });
  }
};

export const getBaseURL = (endpoint: ApiEndPoint, version: 'v1' = 'v1'): string => {
  switch (endpoint) {
    case ApiEndPoint.Auth:
      return `${process.env.REACT_APP_AUTH_API}api/`; // DONE
    case ApiEndPoint.Payment:
      return `${process.env.REACT_APP_PAYMENT_API}api/${version}/payment/`; // DONE
    case ApiEndPoint.Notifications:
      return `${process.env.REACT_APP_NOTIFICATION_API}${version}`; 
    case ApiEndPoint.Support:
      return `${process.env.REACT_APP_SUPPORT_API}support/${version}`;
    case ApiEndPoint.Assessments:
      return `${process.env.REACT_APP_ASSESSMENTS_API}${version}`;
    default:
      return '';
  }
};
