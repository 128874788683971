import loglevel from 'loglevel';
import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';

loglevel.setLevel('info');

export type LogLevel = 'info' | 'warn' | 'error';
type LogPayload = {
  level: LogLevel;
  message: string;
  timestamp: Date;
  additionalData: Record<string, object | string>;
};
const logs: LogPayload[] = [];

const maxTimeDifference = 1000;

function sendLogToBackend(level: LogLevel, message: string, additionalData: Record<string, object | string>) {
  if (logs.length > 0) {
    const lastLogTimestamp = logs[logs.length - 1].timestamp;
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - lastLogTimestamp.getTime();

    if (timeDifference < maxTimeDifference) {
      loglevel.warn('Too many logs sent in a short period of time');
      return;
    }
  }

  if (logs.length > 10) {
    const logsToSend = [...logs];
    const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
    const client = getClient({ baseURL: authBaseUrl });
    client.post('v1/logs', { logs: logsToSend }).catch((err) => loglevel.error(`Failed to send log: ${err.message}`));
    logs.length = 0;
  } else {
    const userId = localStorage.getItem('userId') || 'N/A';
    logs.push({ level, message, timestamp: new Date(), additionalData: { ...additionalData, userId } });
  }
}

export const logger = {
  info: (message: string, additionalData: Record<string, object | string>) => {
    loglevel.info(message);
    sendLogToBackend('info', message, additionalData);
  },
  warn: (message: string, additionalData: Record<string, object | string>) => {
    loglevel.warn(message);
    sendLogToBackend('warn', message, additionalData);
  },
  error: (message: string, additionalData: Record<string, object | string>) => {
    loglevel.error(message);
    sendLogToBackend('error', message, additionalData);
  },
};
