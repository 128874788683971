import { Box, Button, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { updatePassword } from '../../../services/profileService';
import useSnackbar from '../../../hooks/useSnackbar';
import { Context as AuthContext } from '../../../contexts/authContext';
import { TwoFactorFormVerification } from '../2FA/TwoFactorFormVerification';
import { Severity } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    height: 30,
    width: 300,
  },
}));

export function ChangePasswordForm() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCode, setShowCode] = useState(false);
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const toast = useSnackbar();

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast("New Password doesn't match", Severity.error);
      return;
    }
    setShowCode(true);
  };

  const on2FASuccess = async () => {
    try {
      await updatePassword(currentPassword, newPassword, confirmPassword);
      toast('Password changed successfully', Severity.success);
      setShowCode(false);
      toast('Password changed successfully', Severity.success);
    } catch (error) {
      const e = error as any;
      toast(e.message || 'Failed to change password', Severity.error);
    }
  };

  if (!user?.twoFactorEnabled) {
    return (
      <Box width="900px" padding="20px" border="1px solid #ddd" borderRadius="8px" boxShadow="0 2px 4px rgba(0,0,0,0.1)">
        <Typography variant="subtitle1" component="p">
          Change Password:
        </Typography>
        <Typography variant="subtitle1" component="p">
          Enable 2FA to change password
        </Typography>
      </Box>
    );
  }

  if (showCode) {
    return (
      <Box width="900px" padding="20px" border="1px solid #ddd" borderRadius="8px" boxShadow="0 2px 4px rgba(0,0,0,0.1)">
        <Typography style={{ marginBottom: 30 }}>Please confirm 2FA code before changing password:</Typography>
        <TwoFactorFormVerification onVerifySuccess={on2FASuccess} />
      </Box>
    );
  }

  return (
    <Box width="900px" padding="20px" border="1px solid #ddd" borderRadius="8px" boxShadow="0 2px 4px rgba(0,0,0,0.1)">
      <Typography variant="subtitle1">Change Password:</Typography>
      <div>
        <TextField
          label="Current Password"
          variant="outlined"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className={classes.textField}
          inputProps={{
            style: { height: '30px', padding: '6px', lineHeight: '30px' },
          }}
          InputLabelProps={{
            style: { top: '-7px' },
          }}
          InputProps={{
            style: { height: '40px' },
          }}
        />
      </div>
      <div>
        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className={classes.textField}
          inputProps={{
            style: { height: '30px', padding: '6px', lineHeight: '30px' },
          }}
          InputLabelProps={{
            style: { top: '-7px' },
          }}
          InputProps={{
            style: { height: '40px', display: 'flex' },
          }}
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={classes.textField}
          inputProps={{
            style: { height: '30px', padding: '6px', lineHeight: '30px' },
          }}
          InputLabelProps={{
            style: { top: '-7px' },
          }}
          InputProps={{
            style: { height: '40px' },
          }}
        />
      </div>
      <div style={{ marginBottom: 30 }}>
        <Button variant="contained" color="primary" onClick={handleChangePassword} disabled={!newPassword || !currentPassword || !confirmPassword}>
          Change Password
        </Button>
      </div>
    </Box>
  );
}
