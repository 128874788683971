import React, { useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Typography, CircularProgress, makeStyles } from '@material-ui/core';
import { verify2FA } from '../../../services/authService';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 300,
    margin: '0 auto',
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 7),
  },
  codeInput: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignContent: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  codeBox: {
    width: '40px',
    height: '40px',
    fontSize: '24px',
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
}));

type TwoFactorFormVerificationProps = {
  onVerifySuccess: () => Promise<void>;
};

export function TwoFactorFormVerification({ onVerifySuccess }: TwoFactorFormVerificationProps) {
  const [code, setCode] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const inputs = useRef<any>([]);
  const classes = useStyles();

  const handleCodeChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace') {
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const onVerify = async () => {
    try {
      setIsLoading(true);
      await verify2FA(code.join(''));
      await onVerifySuccess();
    } catch (error) {
      setError((error as any).message); // TODO: check this I not sure if this is the correct way to get the error message
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <Box className={classes.codeInput}>
        {[0, 1, 2, 3, 4, 5].map((_, index) => (
          <TextField
            key={index}
            className={classes.codeBox}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              'aria-label': `Digit ${index + 1}`,
            }}
            value={code[index]}
            onChange={(e) => handleCodeChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            inputRef={(el) => (inputs.current[index] = el)}
          />
        ))}
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" className={classes.submit} onClick={onVerify} disabled={isLoading}>
        {!isLoading ? 'Verify' : <CircularProgress color="inherit" />}
      </Button>
    </div>
  );
}
