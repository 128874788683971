import { useState, useEffect } from 'react';
import { CardSettings } from '../BLL/interfaces/tmTypes';
import { getCardByCarrierId, saveCarrierCardSettings } from '../services/tmCards';
import { useAuthContext } from '../contexts/authContext';

const EMPTY_CARD: CardSettings = {
  carrier: {
    logo: '',
    name: 'Carrier Name',
    phoneNumber: '012-1234567',
    medicalPhoneNumber: '*1234',
    website: 'www.example.com',
    email: 'example@doamin.com',
  },
  member: {
    fullName: 'John Doe',
    birthDate: '01/01/1990',
    policyNumber: '123456789',
  },
  card: {
    groupNumber: '1234',
    pcp: undefined, // "Dr. Smith"
    pcpPhone: undefined, // "073-3949222"
    additionalText: '',
    isGradient: false,
    fontColor: '#556cd6',
    primaryBgColor: '#ffffff',
    colorGradientTo: '#ffffff',
    copay: [
      { name: 'PCP', value: '20' },
      { name: 'Specialist', value: '50' },
    ],
  },
};

export default function useTmCard() {
  const [cardSettings, setCardSettings] = useState<CardSettings>(EMPTY_CARD);
  const { user } = useAuthContext();
  const [error, setError] = useState('');

  useEffect(() => {
    const run = async () => {
      if (user?.status === 'new') {
        return;
      }
      try {
        const carrierData = user?.carrier;
        if (!carrierData) {
          setError('Error while retrieving user carrier data');
          setCardSettings(EMPTY_CARD);
          return;
        }
        const currentCardSettings = await getCardByCarrierId(carrierData.id.toString());
        setCardSettings(currentCardSettings);
        setError('');
      } catch (error) {
        setError('Error while retrieving card settings');
        setCardSettings(EMPTY_CARD);
      }
    };
    run();
  }, [user]);

  const applyChanges = async (card: CardSettings) => {
    const carrierData = user?.carrier;
    if (!carrierData) {
      return;
    }
    await saveCarrierCardSettings(carrierData.id.toString(), card);
    setCardSettings(card);
  };

  return { cardSettings, error, applyChanges };
}
