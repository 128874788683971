import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Grid, makeStyles } from "@material-ui/core";

import LoginForm from "./LoginForm/LoginForm";
import RegisterForm from "./RegisterForm/RegisterForm";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import { TmCustomerCardPage } from "../../pages/TmCustomerCardPage";
import { Login2FA } from "./LoginForm/LoginForm2FA";
import ErrorBoundary from "../layout/ErrorBoundary/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    position: "relative",
    backgroundImage: 'url("/tm-sign-bg.png")',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",

    '&:after': {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(74 73 73 / 50%)",
    },
  },
}));

export default function AccountPanel() {
  const classes = useStyles();

  return (
    <Router>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className={classes.image} />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <ErrorBoundary>
            <Switch>
              <Route path="/login-2fa">
                <Helmet>
                  <title>Teramedics Login</title>
                </Helmet>
                <Login2FA />
              </Route>
              <Route path="/login">
                <Helmet>
                  <title>Teramedics Login</title>
                </Helmet>
                <LoginForm />
              </Route>
              <Route path="/card">
                <Helmet>
                  <title>Teramedics Card</title>
                </Helmet>
                <TmCustomerCardPage />
              </Route>
              <Route path="/register">
                <Helmet>
                  <title>Teramedics Register</title>
                </Helmet>
                <RegisterForm />
              </Route>
              <Route path="/forgot-password">
                <Helmet>
                  <title>Teramedics forgot password</title>
                </Helmet>
                <ForgotPasswordForm />
              </Route>
              <Route path="/reset-password">
                <Helmet>
                  <title>Teramedics Reset Password</title>
                </Helmet>
                <ResetPasswordForm />
              </Route>
              <Route path="/">
                <Helmet>
                  <title>Teramedics Login</title>
                </Helmet>
                <LoginForm />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Router>
  );
}
