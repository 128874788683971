import React, { useContext, useState } from 'react';

import { Avatar, Link, Box, Grid, Typography, makeStyles, Tab, Tabs } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Context as AuthContext } from '../../../contexts/authContext';
import RegisterFormPersonal from './RegisterFormPersonal';
import RegisterFormBusiness from './RegisterFormBusiness';
import RegisterFormClinic from './RegisterFormClinic';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  tabs: {
    width: '100%',
    backgroundColor: 'rgb(251, 251, 252, 0.67)',
    borderRadius: '6px',
    marginTop: 10,

    '& button': {
      flex: 1,
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RegisterForm() {
  const classes = useStyles();
  const { errors } = useContext(AuthContext);

  return (
    <div className={classes.paper}>
      {errors && errors.serverError && <Typography color="error">{errors.serverError}</Typography>}
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Register
      </Typography>
      <RegisterFormBusiness />
      <Grid container>
        <Grid item xs>
          <Link href="/forgot-password" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="/login" variant="body2">
            {'Already have an account? Log in here'}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://www.teramedics.com/">
            Teramedics
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </div>
  );
}
