import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Member } from '../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 650,
    margin: '0 auto',

    '& > div': {
      padding: '0 10px',
    },
  },
}));

type MemberEditorProps = {
  onMemberChange: (member: Member) => void;
  member?: Member;
};

export function MemberEditor({ onMemberChange, member }: MemberEditorProps) {
  const classes = useStyles();

  const handleMemberChange = (memberChanges: Member) => {
    // handle errors - check if the member is valid
    onMemberChange(memberChanges);
  };

  if (!member) {
    return null;
  }

  return (
    <div>
      <h2>Member Info {`(${member.relationship === 'P' ? 'Primary' : 'Non-Primary'})`}</h2>
      <div className={classes.fieldContainer}>
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstNameNative"
            label="Native First Name"
            type="text"
            id="firstNameNative"
            autoComplete="current-firstNameNative"
            value={member.firstNameNative}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                firstNameNative: e.target.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastNameNative"
            label="Native Last Name"
            type="text"
            id="lastNameNative"
            autoComplete="current-lastNameNative"
            value={member.lastNameNative}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                lastNameNative: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="First Name"
            type="text"
            id="firstName"
            autoComplete="current-firstName"
            value={member.firstName}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                firstName: e.target.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Last Name"
            type="text"
            id="lastName"
            autoComplete="current-lastName"
            value={member.lastName}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                lastName: e.target.value,
              })
            }
          />
        </div>
      </div>
      <div className={classes.fieldContainer}>
        <div>
          <TextField
            id="effective"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Effective Date"
            type="date"
            defaultValue={
              new Date(member.effectiveDate).toISOString().split('T')[0]
            }
            onChange={(e) =>
              handleMemberChange({
                ...member,
                effectiveDate: new Date(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="termination"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Termination Date"
            type="date"
            defaultValue={
              new Date(member.terminationDate).toISOString().split('T')[0]
            }
            onChange={(e) =>
              handleMemberChange({
                ...member,
                terminationDate: new Date(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            id="tripStartDate"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Trip Start Date"
            type="date"
            defaultValue={
              new Date(member.tripStartDate).toISOString().split('T')[0]
            }
            onChange={(e) =>
              handleMemberChange({
                ...member,
                tripStartDate: new Date(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="tripEndDate"
            fullWidth
            margin="normal"
            variant="outlined"
            label="Trip End Date"
            type="date"
            defaultValue={
              new Date(member.tripEndDate).toISOString().split('T')[0]
            }
            onChange={(e) =>
              handleMemberChange({
                ...member,
                tripEndDate: new Date(e.target.value),
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div
        className={classes.fieldContainer}
        style={{ justifyContent: 'center' }}
      >
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="emailAddress"
            label="Email Address"
            type="text"
            id="emailAddress"
            autoComplete="current-emailAddress"
            value={member.emailAddress}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                emailAddress: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Phone"
            type="text"
            id="phone"
            autoComplete="current-phone"
            value={member.phone}
            // error={!!error}
            // helperText={error?.message}
            onChange={(e) =>
              handleMemberChange({
                ...member,
                phone: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
