import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useCallback, useState } from "react";

type CheckBoxTermOfUseProps = {
  onAgreeChange: (isAgree: boolean) => void;
}

export function CheckBoxTermOfUse({ onAgreeChange }: CheckBoxTermOfUseProps) {
  const [checked, setChecked] = useState(false);

  const onChange = useCallback(
    () => {
      onAgreeChange(checked)
      setChecked(c => !c);
    }, [onAgreeChange, checked],
  )
  

  return <div>
    <FormControlLabel 
      control={ <Checkbox value="agree" color="primary" onChange={() => onChange()} checked={checked}/> }
      label="By registering to Teramedics you are agree to the"
    />
    <a href={`${process.env.REACT_APP_DAS_LP_URL}/terms-of-us`} target="_blank" rel="noreferrer">Terms Of Use</a>
  </div>;
}
