import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: '#333339',
  },
  wrapper: {
    border: '1px solid #9a9b9f',
    borderRadius: 14,
    padding: '20px 20px 0 20px',
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.75)',
    maxWidth: 450,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'normal',
    padding: '0 0 0 0',
  },
  footer: {
    fontSize: 10,
    color: '#9e9e9e',
    height: 30,
  },
  logoLeft: {
    position: 'relative',

    '& img': {
      filter: 'brightness(0)',
      width: 90,

      '&.multiplan': {
        position: 'absolute',
        filter: 'brightness(1)',
        top: 47,
        right: 0,
      },
    },
  },
  gemCaption: {
    position: 'absolute',
    right: -6,
    width: 95,
    fontSize: 7,
    top: 30,
  },
  logoRight: {
    '& img': {
      width: 75,
      cursor: 'pointer',
    },
  },
  para: {
    color: 'inherit',
    fontSize: 12,
    lineHeight: 1.2,
  },
  rx: {
    position: 'absolute',
    right: -8,
    top: 75,
    width: 100,

    '& img': {
      filter: 'brightness(1)',
    },

    '& .supports': {
      fontSize: 10,
      borderTop: '1px solid #e3e3e3',
    },

    '& .supportsPhone': {
      fontSize: 10,
    },
  },
  box: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    border: '1px solid #333339',
    padding: 4,
    width: 200,
    marginTop: 15,
    borderRadius: 5,

    '& .label': {
      fontWeight: 'bold',
      lineHeight: 1.1,
    },
    '& .value': {
      textAlign: 'right',
      lineHeight: 1.1,
    },
  },
  memberInfo: {
    fontSize: 16,
    lineHeight: 1.2,
  },
  memberAndProviderInfo: {
    display: 'flex',
    marginTop: 12,
    textAlign: 'justify',

    '& div:first-child': {
      borderRight: '1px solid #333339', // 2px solid #5d5bb3
      paddingRight: 10,
    },

    '& div:nth-child(2)': {
      paddingLeft: 10,
    },
  },
}));

type MedicalCardProps = {
  children: React.ReactNode;
  bgColor?: string;
  isGradient?: boolean;
  gradientColorFrom?: string;
  gradientColorTo?: string;
  fontColor?: string;
};

type MedicalCardFrontProps = {
  carrierLogo: string;
  carrierName: string;
  memberId?: string;
  carrierNumber?: string;
  memberName: string;
  policyNumber?: string;
  dateOfBirth?: string;
  effectiveDate?: string;
  pcp?: string;
  pcpPhone?: string;
  copay?: { name: string; value: string }[];
  rxId?: string;

  onLogoChange?: (logo: string) => Promise<void>;
};

type MedicalCardBackProps = {
  additionalText?: string;
  carrierWebsite?: string;
  carrierLocalPhone?: string;
  carrierPhoneNumber?: string;
  carrierEmail?: string;
};

export function MedicalCard({ children, bgColor, isGradient, gradientColorFrom, gradientColorTo, fontColor }: MedicalCardProps) {
  const styles = useStyles();
  const customStyles = {
    background: isGradient ? `linear-gradient(45deg, ${gradientColorFrom}, ${gradientColorTo} 50%)` : bgColor || '#ffffff',
    color: fontColor || '#333339',
    width: '670px',
    height: '250px',
  };

  return (
    <div data-id="TM_Card">
      <div className={styles.wrapper} style={customStyles}>
        {children}
      </div>
    </div>
  );
}

MedicalCard.Front = function MedicalCardFront({ onLogoChange, carrierLogo, carrierName, memberName, policyNumber, rxId }: MedicalCardFrontProps) {
  const styles = useStyles();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const logo = reader.result as string;
        if (onLogoChange) {
          await onLogoChange(logo);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logoRight}>
          <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" multiple type="file" onChange={handleFileChange} />
          <label htmlFor="raised-button-file">
            <img src={carrierLogo} alt={carrierName} />
          </label>
        </div>
        <div className={styles.logoLeft}>
          <img src="/gem_logo1.png" alt="Global Excel Management" />
          <Typography variant="caption" className={styles.gemCaption}>
            VOB & Claims Administrator
          </Typography>
          <img src="/multiplan_logo.png" alt="Multiplan" className="multiplan" />
          <div className={styles.rx}>
            <img src="/cvs-caremark-logo.png" alt="Rx" />
            <Typography component="p" variant="caption">
              Rx BIN: 4336
            </Typography>
            <Typography component="p" variant="caption">
              Rx PCN: ADV
            </Typography>
            <Typography component="p" variant="caption">
              Rx GRP: RX6655
            </Typography>
            <Typography component="p" variant="caption">
              Rx ID: {rxId || '1234'}
            </Typography>
            <Typography component="p" variant="caption" className="supports">
              Pharmacy Supports:
            </Typography>
            <Typography component="p" variant="caption" className="supportsPhone">
              1-800-364-6331
            </Typography>
          </div>
        </div>
      </div>
      <Typography component="p" variant="caption" style={{ lineHeight: 1 }}>
        First Name
      </Typography>
      <Typography component="p" className={styles.memberInfo}>
        <span style={{ fontWeight: 'bold' }}>{memberName.split(' ')[0]}</span>
      </Typography>
      <Typography component="p" variant="caption" style={{ lineHeight: 1, marginTop: 6 }}>
        Last Name
      </Typography>
      <Typography component="p" className={styles.memberInfo}>
        <span style={{ fontWeight: 'bold' }}>{memberName.split(' ')[1]}</span>
      </Typography>
      <Typography component="p" className={styles.memberInfo}>
        <span style={{ fontWeight: 'bold' }}>Policy #:</span> {policyNumber}
      </Typography>
      <div className={styles.box}>
        <Typography component="p" variant="caption" className="label">
          Deductible:
        </Typography>
        <Typography component="p" variant="caption" className="value">
          $0.00
        </Typography>
        <Typography component="p" variant="caption" className="label">
          Coinsurance:
        </Typography>
        <Typography component="p" variant="caption" className="value">
          $0.00
        </Typography>
        <Typography component="p" variant="caption" className="label">
          Copay:
        </Typography>
        <Typography component="p" variant="caption" className="value">
          $0.00
        </Typography>
        <Typography component="p" variant="caption" className="label">
          Dental emergency copay:
        </Typography>
        <Typography component="p" variant="caption" className="value">
          $50
        </Typography>
      </div>

      <div className={styles.footer}></div>
    </div>
  );
};
MedicalCard.Back = function MedicalCardBack({ carrierPhoneNumber, carrierEmail }: MedicalCardBackProps) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div>
        <Typography variant="caption" component="p" className={styles.para} style={{ textAlign: 'justify' }}>
          This card does not guarantee benefits. Member is not eligible for elective care. Pre-authorization is required for all inpatient services.
        </Typography>
        <div className={styles.memberAndProviderInfo}>
          <div>
            <Typography component="p">
              <strong style={{ textDecoration: 'underline' }}>Members:</strong>
            </Typography>
            <Typography variant="caption" component="p" className={styles.para}>
              For general inquiries including, Reimbursements and Policy questions, please contact us at:
            </Typography>
            <Typography variant="caption" component="p" className={styles.para}>
              <strong>Phone:</strong> {carrierPhoneNumber}
            </Typography>
            <Typography variant="caption" component="p" className={styles.para}>
              <strong>Email:</strong> {carrierEmail}
            </Typography>
          </div>
          <div>
            <Typography component="p">
              <strong style={{ textDecoration: 'underline' }}>Providers:</strong>
            </Typography>
            <Typography variant="caption" component="p" className={styles.para}>
              For Eligibility, Benefits, and Pre- Authorizations, Claim Submission and Payment: 1-800-###-####
            </Typography>
            <div className={styles.memberAndProviderInfo}>
              <div>
                <Typography variant="caption" component="p" className={styles.para}>
                  <strong>Electronic Payer ID: 65074</strong>
                </Typography>
              </div>
              <div>
                <Typography variant="caption" component="p" className={styles.para}>
                  <strong>Global Excel P.O. Box 10 Beebe Plain, VT 05823</strong>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {/* <Typography variant="caption" component="p" className={styles.para} style={{ textAlign: 'justify' }}>
          VOB & Plan Administrator: Global Excel Management P.O. Box 10, Beebe Plain, VT 05823.
        </Typography> */}
        {/* <Typography variant="caption" component="p" className={styles.para} style={{ direction: 'rtl', marginTop: 20, fontSize: 14 }}>
          במידה והרופא מסרב לקבל את הכרטיס נא הפנה את תשומת ליבו למספר שבגב הכרטיס
        </Typography> */}
      </div>
      <div className={styles.footer}></div>
    </div>
  );
};
