import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { Gender } from '../types';

export type ProfilePersonalUser = {
  email: string;
  firstName: string | null;
  lastName: string | null;
  gender: Gender;
  dateOfBirth: Date | null | undefined;
};

export const updateUserPersonalInfo = async ({ firstName, lastName, gender, dateOfBirth, email }: Partial<ProfilePersonalUser>) => {
  const data = {
    email,
    firstName,
    lastName,
    gender,
    dateOfBirth: dateOfBirth?.toJSON().split('T')[0],
  };
  const client = getClient({ baseURL: process.env.REACT_APP_AUTH_API || '' });
  const response = await client.put('api/users/profile', data);
  return response.data;
};

export const updateUserBusinessInfo = async (companyName: string) => {
  const client = getClient({ baseURL: process.env.REACT_APP_AUTH_API || '' });
  const response = await client.put('api/users/profile-business', { companyName });
  return response.data;
};

export const updatePassword = async (oldPassword: string, newPassword: string, rePassword: string) => {
  const client = getClient({ baseURL: getBaseURL(ApiEndPoint.Auth) });
  const response = await client.put('users/changepassword', { password: oldPassword, newPassword, confirmPassword: rePassword });
  return response.data;
};
