import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { Claim } from '../types';

let claims: Claim[];
let lastRequestTime: number;

export const getAllClaims = async () => {
  if (claims && Date.now() - lastRequestTime < 1000 * 60) {
    return claims;
  }
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const { data } = await client.get<Claim[]>('v1/claims');
  claims = data;
  lastRequestTime = Date.now();
  return claims.map((claim) => ({
    ...claim,
    dateOfService: new Date(claim.dateOfService),
    collectionDate: new Date(claim.collectionDate),
    createdAt: new Date(claim.createdAt),
    updatedAt: new Date(claim.updatedAt),
    totalAllowedAmount: Number(claim.totalAllowedAmount),
    totalBilledAmount: Number(claim.totalBilledAmount),
    total: Number(claim.total),
  }));
};
