import React, { useReducer, useEffect } from "react";
import {
  closeTicketDAS,
  getAllTicketsInfo,
  getTicketDAS,
  openNewTicketDAS,
  sendFeedBackDAS,
  sendMessageDAS,
  SupportTicketInfoExtended,
} from "../../services/supportService";
import { DasUser, SupportTicket } from "../../types";

export interface SupportContextData {
  tickets: SupportTicketInfoExtended[];
  processingRequest: boolean;
  getAllTicketsMetaData: () => Promise<void>;
  getTicket: (id: number | string) => Promise<SupportTicket | null>;
  sendMessage: (
    ticket: SupportTicket,
    message: string,
    user: DasUser
  ) => Promise<void>;
  openNewTicket: (
    issuer: string,
    subject: string,
    message: string
  ) => Promise<void>;
  closeTicket: (ticketId: number | string) => Promise<void>;
  sendFeedBack: (ticketId: number | string, feedback: string) => Promise<void>;
  error: Error | undefined;
}

const supportContextDefaultData: SupportContextData = {
  tickets: [],
  processingRequest: false,
  getAllTicketsMetaData: async () => Promise.resolve(),
  getTicket: async (id: number | string) => Promise.resolve(null),
  sendMessage: async (ticket: SupportTicket, message: string, user: DasUser) =>
    Promise.resolve(),
  openNewTicket: (issuer: string, subject: string, message: string) =>
    Promise.resolve(),
  closeTicket: async (ticketId: number | string) => Promise.resolve(),
  sendFeedBack: async (ticketId: number | string, feedback: string) =>
    Promise.resolve(),
  error: undefined,
};

export const Context = React.createContext(supportContextDefaultData);

type SupportAction =
  | { type: "process_request" }
  | { type: "process_request_done" }
  | { type: "get_tickets"; payload: SupportTicketInfoExtended[] }
  | { type: "get_ticket"; payload: SupportTicket }
  | { type: "process_request_failed"; payload: Error };

interface SupportContextState {
  tickets: SupportTicketInfoExtended[];
  processingRequest: boolean;
  error: any;
}

const initialState: SupportContextState = {
  tickets: [],
  processingRequest: false,
  error: undefined,
};

type SupportProviderProps = {
  children: JSX.Element;
};

const supportReducer = (
  state: SupportContextState = initialState,
  action: SupportAction
): SupportContextState => {
  switch (action.type) {
    case "process_request":
      return { ...state, processingRequest: true };
    case "process_request_done":
      return { ...state, processingRequest: false };
    case "process_request_failed":
      return { ...state, processingRequest: false, error: action.payload };
    case "get_tickets":
      return {
        ...state,
        processingRequest: false,
        error: undefined,
        tickets: action.payload,
      };
    case "get_ticket":
      return { ...state, processingRequest: false, error: undefined };
    default:
      break;
  }
  return state;
};

export const Provider = ({ children }: SupportProviderProps) => {
  const [state, dispatch] = useReducer(supportReducer, initialState);

  useEffect(() => {
    const run = async () => {
      dispatch({ type: "process_request" });
      try {
        // const tickets = await getAllTicketsInfo();
        // dispatch({ type: "get_tickets", payload: tickets });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    };
    run();
  }, []);

  const value: SupportContextData = {
    ...state,
    closeTicket: async (ticketId: number | string) => {
      dispatch({ type: "process_request" });
      try {
        await closeTicketDAS(ticketId);
        dispatch({ type: "process_request_done" });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    },
    getAllTicketsMetaData: async () => {
      dispatch({ type: "process_request" });
      try {
        const tickets = await getAllTicketsInfo();
        dispatch({ type: "get_tickets", payload: tickets });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    },
    getTicket: async (id: number | string) => {
      dispatch({ type: "process_request" });
      try {
        const ticket = await getTicketDAS(id);
        dispatch({ type: "get_ticket", payload: ticket });
        return ticket;
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
        return null;
      }
    },
    sendMessage: async (
      ticket: SupportTicket,
      message: string,
      user: DasUser
    ) => {
      dispatch({ type: "process_request" });
      try {
        await sendMessageDAS(ticket.id, message, user, ticket.issuer);
        dispatch({ type: "process_request_done" });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    },
    openNewTicket: async (issuer: string, subject: string, message: string) => {
      dispatch({ type: "process_request" });
      if (message.length === 0) {
        dispatch({
          type: "process_request_failed",
          payload: new Error("Please fill out the form..."),
        });
        return;
      }
      try {
        const res = await openNewTicketDAS(issuer, subject, message);
        dispatch({ type: "get_tickets", payload: [...state.tickets, res] });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    },
    sendFeedBack: async (ticketId: number | string, feedback: string) => {
      dispatch({ type: "process_request" });
      try {
        await sendFeedBackDAS(ticketId, feedback);
        dispatch({ type: "process_request_done" });
      } catch (error) {
        const e = error as Error;
        dispatch({ type: "process_request_failed", payload: e });
      }
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
