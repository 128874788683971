import React, { useState } from 'react';
import { Button, Container, makeStyles } from '@material-ui/core';
import { SearchMember } from '../../components/SearchMember/SearchMember';
import { MemberFilter } from '../../types';
import { useMemberManipulation } from '../../hooks/useAdminActions';
import { MemberEditor } from '../../components/MemberEditor/MemberEditor';
import ConsentPopup from '../../components/ConsentPopup/ConsentPopup';
import {
  createMemberNotificationsByAdmin,
  deleteMemberCardByAdmin,
} from '../../services/membersService';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const TmAdminMemberInfoPage: React.FC = () => {
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const {
    fetchMember,
    currentMember,
    error,
    clearMember,
    saveMemberChanges,
    isMemberChanged,
    updateMemberLocally,
    memberNotifications,
  } = useMemberManipulation();
  const classes = useStyles();

  const handleSearchClick = async (memberFilter: MemberFilter) => {
    clearMember();
    await fetchMember(memberFilter);
  };

  const resetCardHandler = () => {
    setOpenResetDialog(true);
  };

  const handleResetConfirm = async () => {
    if (!currentMember) {
      return;
    }
    await deleteMemberCardByAdmin(currentMember);
    setOpenResetDialog(false);
  };

  const createNotificationForMember = () => {
    setOpenNotificationDialog(true);
  };
  const handleNotificationConfirm = async () => {
    if (!currentMember) {
      return;
    }
    const response = await createMemberNotificationsByAdmin(currentMember);
    if (response.status === 200 || response.status === 304) {
      // Handle success
    }
    setOpenNotificationDialog(false);
  };

  return (
    <Container className={classes.root}>
      <SearchMember onSearchClick={handleSearchClick} />
      <MemberEditor
        member={currentMember}
        onMemberChange={updateMemberLocally}
      />
      {error && <p>Error: {error.message}</p>}
      {currentMember && (
        <>
          <Button
            onClick={saveMemberChanges}
            disabled={!isMemberChanged}
            color="primary"
            variant="outlined"
          >
            Save Changes
          </Button>
          <Button onClick={resetCardHandler} color="primary" variant="outlined">
            Trigger reset card
          </Button>
          <Button
            onClick={createNotificationForMember}
            color="primary"
            variant="outlined"
          >
            Trigger Notifications
          </Button>
        </>
      )}
      <ConsentPopup
        openDialog={openResetDialog}
        handleConfirm={handleResetConfirm}
        handleCancel={() => setOpenResetDialog(false)}
        text="Are you sure you want to reset the member card?"
      />
      <ConsentPopup
        openDialog={openNotificationDialog}
        handleConfirm={handleNotificationConfirm}
        handleCancel={() => setOpenNotificationDialog(false)}
        text="Are you sure you want to send notifications to the member?"
      />
    </Container>
  );
};

export { TmAdminMemberInfoPage };
