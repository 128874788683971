import React, { createContext, useReducer, useEffect, useContext } from 'react';
import {
  createDasUserWorker,
  deleteDasUserWorker,
  generateNewPAT,
  getCurrentUser,
  resetPasswordDAS,
  sendEmailForReset,
  signInToDAS,
  signout,
  signUpToDAS,
} from '../services/authService';
import { TmUser, UserRole } from '../types';
import { logger } from '../services/logger';
import { isAxiosError } from 'axios';

interface ActionResult {
  isSuccess: boolean;
  message?: string;
}

type authContext = {
  user?: PersonalUserWithToken;
  signIn: (email: string, password: string, code?: string) => void;
  register: (email: string, password: string, role: UserRole, companyName: string, fullName: string, phone: string, twoFactorMethod?: string) => void;
  createUserWorker: (email: string, password: string) => Promise<ActionResult>;
  deleteUserWorker: (email: string) => Promise<ActionResult>;
  sendEmailToResetPassword: (email: string) => Promise<boolean>;
  resetPassword: (email: string, password: string, repassword: string, dbCode: string) => Promise<boolean>;
  clearState: () => void;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
  createPAT: (permissions: string, tokenName: string) => Promise<string>;
  errors: KeyValue;
  isLoading: boolean;
  workerCreationErrors: KeyValue;
  workerCreationisLoading: boolean;
  workerDeletionErrors: KeyValue;
  workerDeletionIsLoading: boolean;
};

type KeyValue = {
  [key: string]: string[];
};

type WorkerMeta = {
  email: string;
  createdAt: string;
};

interface PersonalUserWithToken extends TmUser {
  token: string;
  workers?: WorkerMeta[];
  PAT?: string;
}

type Action =
  | { type: 'request' }
  | { type: 'success'; payload: PersonalUserWithToken }
  | { type: 'failure'; payload: KeyValue }
  | { type: 'request_PAT' }
  | { type: 'success_PAT'; payload: string }
  | { type: 'failure_PAT'; payload: KeyValue }
  // | { type: 'request_2FA' }
  // | { type: 'success_2FA'; payload: PersonalUserWithToken }
  // | { type: 'failure_2FA'; payload: Error }
  | { type: 'request_worker_creation' }
  | { type: 'success_worker_creation' }
  | { type: 'failure_worker_creation'; payload: KeyValue }
  | { type: 'request_worker_deletion' }
  | { type: 'success_worker_deletion' }
  | { type: 'failure_worker_deletion'; payload: KeyValue }
  | { type: 'clear' }
  | { type: 'logout' };
type State = {
  isLoading: boolean;
  user?: PersonalUserWithToken;
  errors?: KeyValue;
  workerCreationErrors?: KeyValue;
  workerCreationisLoading: boolean;
  workerDeletionErrors?: KeyValue;
  workerDeletionIsLoading: boolean;
};

const INIT_STATE: State = {
  isLoading: false,
  workerCreationisLoading: false,
  workerDeletionIsLoading: false,
};

export const Context = createContext<Partial<authContext>>({});

const reducer = (state = INIT_STATE, action: Action): State => {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true };
    case 'request_worker_creation':
      return {
        ...state,
        workerCreationisLoading: true,
        workerCreationErrors: undefined,
      };
    case 'request_worker_deletion':
      return {
        ...state,
        workerDeletionIsLoading: false,
        workerDeletionErrors: undefined,
      };
    case 'success':
      return { ...state, isLoading: false, user: action.payload };
    case 'success_worker_creation':
      return {
        ...state,
        workerCreationisLoading: false,
        workerCreationErrors: undefined,
      };
    case 'success_worker_deletion':
      return {
        ...state,
        workerDeletionIsLoading: false,
        workerDeletionErrors: undefined,
      };
    case 'failure':
      return {
        ...state,
        isLoading: false,
        user: undefined,
        errors: action.payload,
      };
    case 'failure_worker_creation':
      return {
        ...state,
        workerCreationisLoading: false,
        workerCreationErrors: action.payload,
      };
    case 'failure_worker_deletion':
      return {
        ...state,
        workerDeletionIsLoading: false,
        workerDeletionErrors: action.payload,
      };
    case 'clear':
      return { ...state, isLoading: false, errors: undefined };
    case 'logout':
      return { ...state, isLoading: false, user: undefined, errors: undefined };
    case 'request_PAT':
      return { ...state, isLoading: true };
    case 'success_PAT':
      return { ...state, user: { ...state.user!, PAT: action.payload }, isLoading: false };
    case 'failure_PAT':
      return { ...state, isLoading: false, errors: action.payload };
    // case 'request_2FA':
    //   return { ...state, isLoading: true };
    // case 'success_2FA':
    //   return { ...state, user: { ...state.user! }, isLoading: false };
    // case 'failure_2FA':
    //   return { ...state, isLoading: false, errors: { message: [action.payload.message] } };
  }
};

const loadUser = async (dispatch: (action: Action) => void) => {
  try {
    const user = await getCurrentUser();
    localStorage.setItem('userId', user?.id.toString() || '');
    if (user) {
      dispatch({ type: 'success', payload: user });
    }
  } catch (error) {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
};

export const Provider = ({ children }: { children: React.ReactNode[] }) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  useEffect(() => {
    loadUser(dispatch);
  }, []);

  const value = {
    user: state.user,
    signIn: async (email: string, password: string, code?: string): Promise<void> => {
      dispatch({ type: 'request' });
      const response = await signInToDAS(email, password, code);
      if (response.data === null && response.errors && response.errors[0] === '2FA required') {
        throw new Error('Waiting for 2FA');
      }
      if (response.errors === null) {
        dispatch({ type: 'success', payload: response.data });
      } else {
        dispatch({
          type: 'failure',
          payload: { serverError: response.errors },
        });
      }
    },
    register: async (
      email: string,
      password: string,
      role: UserRole,
      companyName: string,
      fullName: string,
      phone: string,
      twoFactorMethod?: string
    ) => {
      try {
        dispatch({ type: 'request' });
        const response = await signUpToDAS(email, password, role, companyName, fullName, phone, twoFactorMethod);
        dispatch({ type: 'success', payload: response.data });
      } catch (error) {
        dispatch({ type: 'failure', payload: error as KeyValue });
      }
    },
    sendEmailToResetPassword: async (email: string): Promise<boolean> => {
      try {
        dispatch({ type: 'request' });
        const response = await sendEmailForReset(email);
        dispatch({ type: 'success', payload: response.data });
        return true;
      } catch (error) {
        dispatch({ type: 'failure', payload: error as KeyValue });
      }
      return false;
    },
    resetPassword: async (email: string, password: string, rePassword: string, dbCode: string): Promise<boolean> => {
      try {
        dispatch({ type: 'request' });
        await resetPasswordDAS(email, password, rePassword, dbCode);
        dispatch({ type: 'clear' });
        return true;
      } catch (error) {
        if (isAxiosError(error)) {
          logger.error('Error in reset password', { error });
        }
        // error.response.data.errors => {field, message}[]
        dispatch({
          type: 'failure',
          payload: {
            serverError: ['Something went wrong, please try to resend reset password email'],
          },
        });
      }
      return false;
    },
    clearState: () => {
      dispatch({ type: 'clear' });
    },
    logout: async () => {
      await signout();
      dispatch({ type: 'logout' });
    },
    createUserWorker: async (email: string, password: string) => {
      try {
        dispatch({ type: 'request_worker_creation' });
        await createDasUserWorker(email, password);
        dispatch({ type: 'success_worker_creation' });
        return {
          isSuccess: true,
        };
      } catch (error) {
        const e = error as any; // TODO: add guard
        dispatch({ type: 'failure_worker_creation', payload: { error: e } });
        return {
          isSuccess: false,
          message: e.message as string,
        };
      }
    },
    deleteUserWorker: async (email: string) => {
      try {
        dispatch({ type: 'request_worker_deletion' });
        await deleteDasUserWorker(email);
        dispatch({ type: 'success_worker_deletion' });
        return {
          isSuccess: true,
        };
      } catch (error) {
        const e = error as any; // TODO: add guard
        dispatch({ type: 'failure_worker_deletion', payload: { error: e } });
        return {
          isSuccess: false,
          message: error as string,
        };
      }
    },
    refreshUser: async () => {
      loadUser(dispatch);
    },
    createPAT: async (permissions: string, tokenName: string) => {
      let PAT = '';
      try {
        dispatch({ type: 'request_PAT' });
        if (!state.user) {
          throw new Error('User is not logged in');
        }
        PAT = await generateNewPAT(state.user.email, permissions, tokenName);
        dispatch({ type: 'success_PAT', payload: '' });
      } catch (error) {
        const e = error as any; // TODO: add guard
        dispatch({ type: 'failure_PAT', payload: e.message });
      }
      return PAT;
    },
    errors: state.errors,
    isLoading: state.isLoading,
  };

  const showLoginPanel = () => {
    return !state.user || !state.user.token;
  };
  return <Context.Provider value={value}>{showLoginPanel() ? children[0] : children[1]}</Context.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(Context);
  if (context === null) {
    throw new Error('No provider for this context');
  }
  return context;
};
