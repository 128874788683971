import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Grid, Typography, CircularProgress, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Context as AuthContext } from '../../../contexts/authContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, errors, isLoading, clearState } = useContext(AuthContext);
  const history = useHistory();

  const onSignIn = async () => {
    if (signIn) {
      try {
        await signIn(email, password);
      } catch (e) {
        history.push('/login-2fa', { email, password });
      }
    }
  };

  const onFocus = () => {
    if (clearState) {
      clearState();
    }
  };

  return (
    <div className={classes.paper}>
      {errors && errors.serverError && <Typography color="error">Failed, Please check user name and password and try again</Typography>}
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Log in
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          error={(errors && errors.email && errors.email.length > 0) || undefined}
          helperText={errors && errors.email && errors.email.join(', ')}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={onFocus}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          error={(errors && errors.password && errors.password.length > 0) || undefined}
          helperText={errors && errors.password && errors.password.join(', ')}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={onFocus}
        />
        <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
        <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={onSignIn} disabled={isLoading}>
          {!isLoading ? 'Log in' : <CircularProgress color="inherit" />}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              {"Don't have an account? Register here"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.teramedics.com/">
              Teramedics
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </form>
    </div>
  );
}
