import React, { useState, useContext } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Grid, Typography, CircularProgress, makeStyles } from "@material-ui/core";
import { Context as AuthContext } from "../../../contexts/authContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
}));

export default function ForgotPasswordForm() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const {
    sendEmailToResetPassword,
    isLoading,
    errors,
    clearState,
  } = useContext(AuthContext);

  const sendEmail = async () => {
    if (sendEmailToResetPassword) {
      const isSent = await sendEmailToResetPassword(email);
      if (isSent) {
        setIsEmailSent(true);
      }
    }
  };
  const onFocus = () => {
    if (clearState) {
      clearState();
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h2" variant="body1">
        Please enter your eamail.
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={(errors && errors.email.length > 0) || undefined}
          helperText={errors && errors.email[0]}
          onFocus={onFocus}
          disabled={isEmailSent || undefined}
        />
        {isEmailSent && (
          <Typography color="textSecondary" variant="caption">
            Email is waiting for you in your inbox
          </Typography>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={sendEmail}
          disabled={isLoading || isEmailSent}
        >
          {!isLoading ? "Send email" : <CircularProgress color="inherit" />}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/" variant="body2">
              Sign in
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.teramedics.com/">
              Teramedics
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </form>
    </div>
  );
}
