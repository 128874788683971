import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { DasUser, SupportTicket, SupportTicketInfo, TicketStatus, UserRole } from '../types';

interface SupportTicketInfoResponse {
  id: number;
  createdAt: string;
  issuer: string;
  status: {
    name: string;
    id: number;
  };
  messages: {
    from: string;
    to: string;
    message: string;
    sentAt: string;
    id: number;
  }[];
  subject: string;
  updatedAt: string;
}

interface SupportTicketConversationItem {
  from: { email: string };
  to: { email: string };
  message: string;
  ack: 'sent';
  imageUrl: undefined;
  sentAt: Date;
}

export interface SupportTicketInfoExtended extends SupportTicketInfo {
  name: string;
}

function getTicketStatus(status: string): TicketStatus {
  switch (status) {
    case TicketStatus.Closed:
      status = TicketStatus.Closed;
      break;
    case TicketStatus.PendingResponse:
      status = TicketStatus.PendingResponse;
      break;
    case TicketStatus.Reopened:
      status = TicketStatus.Reopened;
      break;
  }
  return TicketStatus.Open;
}

export const closeTicketDAS = async (ticketId: number | string) => {
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};

export const getAllTicketsInfo = async (): Promise<SupportTicketInfoExtended[]> => {
  const supportBaseUrl = getBaseURL(ApiEndPoint.Support);
  const client = getClient({ baseURL: supportBaseUrl });
  const res = await client.get<SupportTicketInfoResponse[]>('supporttickets/getall');

  return res.data.map<SupportTicketInfoExtended>((t) => ({
    createdAt: new Date(t.createdAt),
    id: t.id,
    name: t.issuer,
    status: getTicketStatus(t.status.name),
    updatedAt: new Date(t.updatedAt),
    from: t.issuer
  }));
};

export const sendMessageDAS = async (
  ticketId: number | string,
  message: string,
  from: DasUser,
  issuer: string
): Promise<SupportTicketConversationItem> => {
  const data = {
    id: ticketId,
    issuer,
    status: TicketStatus.Open,
    ticketMessage: {
      from: from.role === UserRole.superUser ? 'support@drawinganalysis.com' : from.email,
      to: from.role === UserRole.superUser ? issuer : 'support@drawinganalysis.com',
      message,
    },
  };
  const supportBaseUrl = getBaseURL(ApiEndPoint.Support);
  const client = getClient({ baseURL: supportBaseUrl });
  await client.put('supporttickets', data);
  return {
    from: { email: data.ticketMessage.from },
    to: { email: data.ticketMessage.to },
    message,
    ack: 'sent',
    imageUrl: undefined,
    sentAt: new Date(),
  };
};

export const openNewTicketDAS = async (issuer: string, subject: string, message: string): Promise<SupportTicketInfoExtended> => {
  const supportBaseUrl = getBaseURL(ApiEndPoint.Support);
  const client = getClient({ baseURL: supportBaseUrl });
  const res = await client.post('supporttickets', {
    issuer,
    subject,
    ticketMessage: {
      from: issuer,
      to: 'support@drawinganalysis.com',
      message,
    },
  });
  return res.data;
};

export const sendFeedBackDAS = async (ticketId: number | string, feedback: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    console.log(`sending feedback for ticketId ${ticketId} with message ${feedback}`);
    resolve();
  });
};

export const getTicketDAS = async (ticketId: number | string): Promise<SupportTicket> => {
  const supportBaseUrl = getBaseURL(ApiEndPoint.Support);
  const client = getClient({ baseURL: supportBaseUrl });
  const res = await client.get<SupportTicketInfoResponse>(`supporttickets/${ticketId}`);

  return {
    id: res.data.id,
    issuer: res.data.issuer,
    status: getTicketStatus(res.data.status.name),
    subject: res.data.subject,
    ticketTrackingId: res.data.id,
    conversation: res.data.messages.map(({ from, to, sentAt, message }) => ({
      from: {
        email: from,
      },
      to: {
        email: to,
      },
      sentAt: new Date(sentAt),
      message,
      ack: 'sent',
      imageUrl: undefined,
    })),
  };
};
