import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import useStyles from '../../../pages/HomePage.style';
import ProfilePage from '../../../pages/ProfilePage';
import ClaimsPage from '../../../pages/ClaimsPage';

import { Provider as SupportProvider } from '../../../contexts/support/supportContext';
import { useLanguageContext } from '../../../contexts/langContext';
import { TmAdminPage } from '../../../pages/TmAdminPage';
import PaymentPage from '../../../pages/PaymentPage';
import SupportPage from '../../../pages/SupportPage';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { TmAdminMemberInfoPage } from '../../../pages/Admin/TmAdminMemberInfoPage';


export default function DashboardContent() {
  const classes = useStyles();
  const { dir } = useLanguageContext();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container} style={{ direction: dir }}>
        <Grid container spacing={3}>
          <ErrorBoundary>
            <Switch>
              <Route path="/billing">
                <Helmet>
                  <title>Teramedics Anaylsis Quota</title>
                </Helmet>
                <Grid item xs={12}>
                  <PaymentPage />
                </Grid>
              </Route>
              <Route path="/profile">
                <Helmet>
                  <title>Profile</title>
                </Helmet>
                <Grid container className={classes.gridContainer}>
                  <ProfilePage /> {/* TODO: work on the profile page for carriers */}
                </Grid>
              </Route>
              {/* <Route path="/support/new" exact>
                <Helmet>
                  <title>Teramedics New Support Ticket</title>
                </Helmet>
                <Grid item xs={12}>
                  <SupportProvider>
                    <SupportPageNewTicket />
                  </SupportProvider>
                </Grid>
              </Route> */}
              {/* <Route path="/support/history" exact>
                <Helmet>
                  <title>Teramedics Support Tickets</title>
                </Helmet>
                <Grid item xs={12}>
                  <SupportProvider>
                    <SupportPageUserHistory />
                  </SupportProvider>
                </Grid>
              </Route> */}
              {/* <Route path="/support/:ticketId">
                <Helmet>
                  <title>Teramedics Support</title>
                </Helmet>
                <Grid item xs={12}>
                  <SupportProvider>
                    <SupportPageConversation />
                  </SupportProvider>
                </Grid>
              </Route> */}
              <Route path="/support">
                <Helmet>
                  <title>Teramedics Support</title>
                </Helmet>
                <Grid item xs={12}>
                  <SupportProvider>
                    <SupportPage />
                  </SupportProvider>
                </Grid>
              </Route>
              <Route path="/admin">
                <Helmet>
                  <title>TM Admin Page</title>
                </Helmet>
                <Grid item xs={12}>
                  <TmAdminPage />
                </Grid>
              </Route>
              <Route path="/member-info">
                <Helmet>
                  <title>TM Admin member info</title>
                </Helmet>
                <Grid item xs={12}>
                  <TmAdminMemberInfoPage />
                </Grid>
              </Route>
              <Route path="/">
                <Helmet>
                  <title>Claims</title>
                </Helmet>
                {/* AnalysisPage */}
                <Grid container className={classes.gridContainer}>
                  <ClaimsPage />
                </Grid>
              </Route>
            </Switch>
          </ErrorBoundary>
        </Grid>
      </Container>
    </main>
  );
}
