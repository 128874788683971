import { getBaseURL, getClient } from '../BLL/clients/HttpClient';
import { ApiEndPoint } from '../BLL/Enums/ApiEndPoint';
import { CardSettings } from '../BLL/interfaces/tmTypes';
import { CardSettingsServerResponse } from '../types';

export const getCardByCarrierId = async (carrierId: string): Promise<CardSettings> => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const res = await client.get<CardSettingsServerResponse>('v1/card-settings', { params: { carrierId } });
  const { data } = res;
  return {
    card: {
      pcp: '',
      pcpPhone: '',
      additionalText: data.additionalText,
      isGradient: data.isGradient,
      groupNumber: data.groupNumber,
      primaryBgColor: data.primaryBgColor,
      fontColor: data.fontColor,
      colorGradientTo: data.colorGradientTo,
      copay: data.copay,
    },
    carrier: {
      logo: data.carrierLogo,
      name: data.carrierName,
      phoneNumber: data.carrierPhone,
      medicalPhoneNumber: data.carrierNameMedicalPhoneNumber,
      website: data.carrierWebsite,
      email: data.carrierEmail,
    },
    member: {
      fullName: 'John Doe',
      birthDate: '01/01/1990',
      policyNumber: '123456789',
    },
  };
};

export const saveCarrierCardSettings = async (carrierId: string, cardSettings: CardSettings): Promise<void> => {
  const authBaseUrl = getBaseURL(ApiEndPoint.Auth);
  const client = getClient({ baseURL: authBaseUrl });
  const data: CardSettingsServerResponse = {
    additionalText: cardSettings.card.additionalText,
    isGradient: cardSettings.card.isGradient,
    carrierLogo: cardSettings.carrier.logo,
    carrierName: cardSettings.carrier.name,
    carrierNameMedicalPhoneNumber: cardSettings.carrier.medicalPhoneNumber,
    colorGradientTo: cardSettings.card.colorGradientTo,
    fontColor: cardSettings.card.fontColor,
    groupNumber: cardSettings.card.groupNumber,
    primaryBgColor: cardSettings.card.primaryBgColor,
    copay: cardSettings.card.copay,
    carrierPhone: cardSettings.carrier.phoneNumber,
    carrierWebsite: cardSettings.carrier.website,
    carrierId: parseInt(carrierId),
    cardType: 'GEM_DEFAULT',
    carrierEmail: cardSettings.carrier.email,
  };
  await client.post('v1/card-settings', data);
};
