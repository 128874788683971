import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';

import useStyles from './HomePage.style';
import SideBar from '../components/layout/SideBar/SideBar';
import AppHeader from '../components/layout/AppHeader/AppHeader';
import DashboardContent from '../components/layout/DashboardContent/DashboardContent';

import { Provider as SnackbarProvider } from '../contexts/SnackbarContext';
import { useLanguageContext } from '../contexts/langContext';
// import ChangeLangFlotingButton from "../components/DevComponents/ChangeLangFloatingButton/ChangeLangFloatingButton";

const isMobile = 'ontouchstart' in document.documentElement;

export default function HomePage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(!isMobile);
  const { dir } = useLanguageContext();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <SnackbarProvider>
      {/* <ChangeLangFlotingButton /> */}
      <div className={classes.root}>
        <CssBaseline />
        <AppHeader isOpen={open} onDrawerOpen={toggleDrawer} />
        <Router>
          {dir === 'ltr' ? (
            <>
              <SideBar isOpen={open} onDrawerClose={toggleDrawer} />
              <DashboardContent />
            </>
          ) : (
            <>
              <DashboardContent />
              <SideBar isOpen={open} onDrawerClose={toggleDrawer} />
            </>
          )}
        </Router>
      </div>
    </SnackbarProvider>
  );
}
