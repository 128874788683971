import React, { useEffect, useState } from 'react';
import DasDataSet, { DasDataSetCol } from '../components/DasDataSet/EnhancedTable';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/authContext';
import { Box, Container, Typography } from '@material-ui/core';
import { getAllClaims } from '../services/claimsService';
import { Claim } from '../types';

type ClaimsPageData = {
  entities: Claim[];
  status: 'loading' | 'done' | 'error';
  error: null | Error;
};

export default function ClaimsPage() {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [pageData, setPageData] = useState<ClaimsPageData>({ entities: [], status: 'loading', error: null });

  const col: DasDataSetCol[] = [
    {
      id: 'id',
      label: t('Claim Id'),
      width: 200,
      type: 'id',
    },
    {
      id: 'memberId',
      label: t('Member ID'),
      type: 'text',
    },
    {
      id: 'dateOfService',
      label: t('Date of Service'),
      type: 'text',
    },
    {
      id: 'providerName',
      label: t('Provider Name'),
      width: 550,
      type: 'date',
    },
    {
      id: 'npi',
      label: t('NPI'),
      type: 'text',
    },
    {
      id: 'totalBilledAmount',
      label: 'Billed Amount',
      type: 'text',
    },
    {
      id: 'totalAllowedAmount',
      label: 'Allowed Amount',
      type: 'text',
    },
    {
      id: 'collectionDate',
      label: 'Collection Date',
      type: 'text',
    },
    {
      id: 'action',
      label: 'Details',
      type: 'action',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const claims = await getAllClaims();
        setPageData({ entities: claims, status: 'done', error: null });
      } catch (error) {
        const e = error as Error;
        setPageData({ entities: [], status: 'error', error: e });
      }
    };
    fetchData();
  }, []);

  if (!user) {
    return <div>{t('Loading')}..</div>;
  }

  if (user.status === 'new') {
    return (
      <Container>
        <Typography variant="h4" component="p">
          Welcome to Teramedics Dashboard!
        </Typography>
        <Typography component="p">To activate your account and access all Teramedics features please contact us</Typography>
      </Container>
    );
  }
  if (pageData.status === 'loading') {
    return <div>{t('Loading')}..</div>;
  }

  if (pageData.error) {
    return <div>{t('Error')}..</div>;
  }

  if (pageData.entities.length === 0) {
    return (
      <Box display="flex" alignContent="center" flexDirection="column" justifySelf="center" margin="0 auto">
        <img src="/no-res.png" alt="No Claims" style={{ width: '100px', height: 'auto', margin: '0 auto' }} />
        <Typography style={{ marginTop: 40 }} variant="h4">
          There are no claims in the system currently
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ maxWidth: '80vw', margin: '0 auto' }}>
      <DasDataSet columns={col} rows={pageData.entities} />
    </div>
  );
}
