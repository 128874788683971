import React from 'react';
import { useAuthContext } from '../contexts/authContext';
import { Box, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

export default function PaymentPage() {
  const { user } = useAuthContext();

  if (!user) {
    return <div>Loading...</div>;
  }

  if (user.status === 'new') {
    return (
      <Container>
        <Typography variant="h4" component="p">
          Welcome to Teramedics Dashboard!
        </Typography>
        <Typography component="p">To activate your account and access all Teramedics features please contact us</Typography>
      </Container>
    );
  }

  if (!user.carrier || !user.carrier.billingSettings) {
    return (
      <Container>
        <Typography variant="h4" component="p">
          Welcome to Teramedics Billing Dashboard!
        </Typography>
        <Typography component="p">Please contact Teramedics team to setup your carrier settings</Typography>
      </Container>
    );
  }

  const { costPerDayFixed, costPerDaySpecial, minimumMonthlyCost, pilotNumberOfDays, contactEmail } = user.carrier.billingSettings;

  return (
    <Box>
      <Typography variant="h4">Billing Information</Typography>
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Pricing Terms:
      </Typography>
      <Table style={{ width: 400, border: '1px solid #e3e3e3' }}>
        <TableHead>
          <TableRow>
            <TableCell># of insurance days</TableCell>
            <TableCell style={{ borderLeft: '1px solid #e3e3e3' }}>Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1">1 - {Intl.NumberFormat().format(pilotNumberOfDays)}</Typography>
            </TableCell>
            <TableCell style={{ borderLeft: '1px solid #e3e3e3' }}>
              <Typography variant="body1">${costPerDaySpecial}</Typography>
            </TableCell>
          </TableRow>
          {costPerDayFixed && costPerDayFixed > costPerDaySpecial && (
            <TableRow>
              <TableCell>
                <Typography variant="body1">{Intl.NumberFormat().format(pilotNumberOfDays + 1)} and above</Typography>
              </TableCell>
              <TableCell style={{ borderLeft: '1px solid #e3e3e3' }}>
                <Typography variant="body1">${costPerDayFixed}</Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant="body1">In patient</Typography>
            </TableCell>
            <TableCell style={{ borderLeft: '1px solid #e3e3e3' }}>
              <Typography variant="body1">%14</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {minimumMonthlyCost && minimumMonthlyCost > 0 ? (
        <Typography variant="body1" style={{ marginTop: 10 }}>
          Minimum cost per month: ${minimumMonthlyCost}
        </Typography>
      ) : null}

      <Typography variant="h6" style={{ marginTop: 20 }}>
        Billing contact:
      </Typography>
      <Typography variant="body1" style={{ marginTop: 10 }}>
        Billing Contact Person: {contactEmail}
      </Typography>
    </Box>
  );
}
