import React, { useState } from 'react';
import { Member, MemberFilter, TmNotification } from '../types';
import { getMemberForAdmin, saveMemberForAdmin } from '../services/membersService';
import { AxiosError } from 'axios';

const isMemberEqual = (member1: any, member2: any) => {
  for (const key in member1) {
    if (member1[key] !== member2[key]) {
      return false;
    }
  }
  for (const key in member2) {
    if (member1[key] !== member2[key]) {
      return false;
    }
  }
  return true;
};

export function useMemberManipulation() {
  const [currentMember, setCurrentMember] = useState<Member | undefined>();
  const [memberNotifications, setMemberNotifications] = useState<TmNotification[]>([]);
  const [originalMember, setOriginalMember] = useState<Member | undefined>();
  const [isMemberChanged, setMemberChanged] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const fetchMember = async (memberFilter: MemberFilter) => {
    try {
      const member = await getMemberForAdmin(memberFilter.searchBy, memberFilter.value);
      const { notifications, ...memberWithoutNotification } = member;
      setCurrentMember(memberWithoutNotification);
      setOriginalMember(memberWithoutNotification);
      setMemberNotifications(notifications || []);
    } catch (e) {
      setError(e as AxiosError);
    }
  };

  const clearMember = () => {
    setCurrentMember(undefined);
    setOriginalMember(undefined);
    setError(undefined);
    setMemberChanged(false);
    setMemberNotifications([]);
  };

  const updateMemberLocally = (member: Member) => {
    setCurrentMember(member);
    setMemberChanged(!isMemberEqual(member, originalMember));
  };

  const saveMemberChanges = async () => {
    if (currentMember && isMemberChanged) {
      try {
        await saveMemberForAdmin(currentMember);
        setOriginalMember(currentMember);
        setMemberChanged(false);
      } catch (e) {
        setError(e as AxiosError);
      }
    }
  };

  return { fetchMember, currentMember, error, clearMember, updateMemberLocally, saveMemberChanges, isMemberChanged, memberNotifications };
}
