import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationHEB from './locales/he/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  he: {
    translation: translationHEB,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    react: {
      useSuspense: false,
    }
  });

  // {
  //   resources,
  //   lng: 'en',
  //   fallbackLng: 'en',
  //   keySeparator: false, // we do not use keys in form messages.welcome
  //   interpolation: {
  //     escapeValue: false,
  //   },
  //   react: {
  //     wait: true,
  //   },
  // }

export default i18n;
