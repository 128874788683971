import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';

type ConsentPopupProps = {
  openDialog: boolean;
  handleCancel: () => void | Promise<void>;
  handleConfirm: () => void | Promise<void>;
  text: string;
};

export default function ConsentPopup({
  openDialog,
  handleCancel,
  handleConfirm,
  text,
}: ConsentPopupProps) {
  return (
    <Dialog open={openDialog} onClose={handleCancel}>
      <DialogTitle>Confirm Reset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
