import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, TextField, Button, Icon } from '@material-ui/core';
import { useAuthContext } from '../../../contexts/authContext';
import { KeyPermissionsType, PermissionsToggle } from './PermissionsToggle';
import SaveKeyModal from './SaveKeyModal';
import { ApiKeyInfo } from '../../../types';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #e3e3e3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 500,
    borderRadius: 14,
  },
}));

type CreateKeyModalProps = {
  open: boolean;
  onClose: () => void;
  onCreate: (key: ApiKeyInfo) => void;
  currentKey?: ApiKeyInfo;
};

export const CreateKeyModal = ({ open, onClose, onCreate, currentKey }: CreateKeyModalProps) => {
  const classes = useStyles();
  const { user, createPAT, isLoading: isLoadingPAT, errors, clearState } = useAuthContext();
  const [showSaveKeyModal, setShowSaveKeyModal] = useState(false);
  const [currentSecret, setSecret] = useState('');
  const [currentPermissions, setCurrentPermissions] = useState('');
  const [apiKey, setApiKey] = useState<ApiKeyInfo>({
    name: '',
    createdAt: new Date().toLocaleDateString(),
    updatedAt: 'Never',
    createdBy: '',
    permissions: 'read',
    aud: '',
    status: 'new',
  });

  useEffect(() => {
    if (currentKey) {
      setApiKey(currentKey);
    }
    return () => {
      clearState && clearState();
    };
  }, [currentKey]);

  const handleCreate = async () => {
    if (!createPAT) {
      return;
    }
    const secret = await createPAT(apiKey.permissions, apiKey.name);
    if (!secret) {
      return;
    }
    setSecret(secret);
    setCurrentPermissions(apiKey.permissions);
    const newKey: ApiKeyInfo = {
      ...apiKey,
      createdAt: new Date().toLocaleDateString(),
      updatedAt: 'Never',
      createdBy: user?.email || 'Unknown User',
      status: 'active',
    };
    onCreate(newKey);
    setApiKey({
      name: '',
      createdAt: new Date().toLocaleDateString(),
      updatedAt: 'Never',
      createdBy: '',
      permissions: 'read',
      aud: '',
      status: 'new',
    });
    setShowSaveKeyModal(true);
  };

  return (
    <div>
      <Modal
        aria-labelledby="create-key-modal-title"
        aria-describedby="create-key-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 style={{ marginBottom: 0 }}>Create New API Key</h2>
            <TextField
              fullWidth
              label="Key Name"
              margin="normal"
              value={apiKey.name}
              onChange={(e) => {
                setApiKey({ ...apiKey, name: e.target.value });
                if (clearState) {
                  clearState();
                }
              }}
              error={Boolean(errors)}
              helperText={errors}
              disabled={apiKey.status !== 'new'}
            />
            <PermissionsToggle
              selectedPermission={apiKey.permissions as KeyPermissionsType}
              onPermissionChange={(p) => setApiKey({ ...apiKey, permissions: p })}
            />
            <Button variant="contained" color="primary" onClick={handleCreate} disabled={isLoadingPAT}>
              {!isLoadingPAT ? 'Create' : <Icon className="fas fa-spinner fa-spin" />}
            </Button>
          </div>
        </Fade>
      </Modal>
      <SaveKeyModal open={showSaveKeyModal} onClose={() => setShowSaveKeyModal(false)} secretKey={currentSecret} permissions={currentPermissions} />
    </div>
  );
};
