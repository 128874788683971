import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  toggleGroup: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    height: 30,
  },
  toggleButton: {
    borderRadius: 4,
    height: 30,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

export type KeyPermissionsType = 'read' | 'read:write';

type PermissionsToggleProps = {
  selectedPermission: KeyPermissionsType;
  onPermissionChange: (permission: KeyPermissionsType) => void;
};

export const PermissionsToggle = ({ selectedPermission, onPermissionChange }: PermissionsToggleProps) => {
  const classes = useStyles();

  const handlePermissionChange = (event: React.MouseEvent<HTMLElement>, newPermission: KeyPermissionsType) => {
    onPermissionChange(newPermission);
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup value={selectedPermission} exclusive onChange={handlePermissionChange} className={classes.toggleGroup}>
        <ToggleButton value="read:write" className={classes.toggleButton}>
          read:write
        </ToggleButton>
        <ToggleButton value="read" className={classes.toggleButton}>
          read
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
