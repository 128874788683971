import React, { useEffect, useState } from 'react';
import DasDataSet, { DasDataSetCol } from '../components/DasDataSet/EnhancedTable';
import { useTranslation } from 'react-i18next';
import { Container, Divider } from '@material-ui/core';
import { Member } from '../types';
import { calculateTotalRevenuePerDay, calculateTotalRevenuePerMonth, getAllMembers, getInsuranceDaysPerCarrier, InsuranceDaysPerCarrier } from '../services/membersService';

type MembersPageData = {
  entities: Member[];
  status: 'loading' | 'done' | 'error';
  error: null | Error;
};

const perDayColumns: DasDataSetCol[] = [
  {
    id: 'carrierId',
    label: 'Carrier ID',
    type: 'id',
  },
  {
    id: 'issueDate',
    label: 'Issue Date',
    width: 200,
    type: 'date',
  },
  {
    id: 'insuranceDays',
    label: 'Number of insurance days',
    type: 'text',
  },
  {
    id: 'amountPerDay',
    label: 'Amount per day',
    width: 550,
    type: 'text',
  },
  {
    id: 'totalRevenue',
    label: 'Total revenue',
    type: 'text',
  },
];

const cols: DasDataSetCol[] = [
  {
    id: 'customerName',
    label: 'Customer Name',
    width: 200,
    type: 'id',
  },
  {
    id: 'month',
    label: 'Month',
    type: 'text',
  },
  {
    id: 'year',
    label: 'Year',
    type: 'text',
  },
  {
    id: 'totalInsuranceDays',
    label: 'Total number of insurance days',
    type: 'text',
  },
];

export function TmAdminPage() {
  const { t } = useTranslation();
  const [pageData, setPageData] = useState<MembersPageData>({ entities: [], status: 'loading', error: null });
  const [result, setResult] = useState<InsuranceDaysPerCarrier>({});
  // const toast = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const members = await getAllMembers();
        setPageData({ entities: members, status: 'done', error: null });
        const res = getInsuranceDaysPerCarrier(members);
        setResult(res);
      } catch (error) {
        const e = error as Error;
        setPageData({ entities: [], status: 'error', error: e });
      }
    };
    fetchData();
  }, []);

  if (pageData.status === 'loading') {
    return <div>Loading..</div>;
  }

  return (
    <>
      <Container style={{ paddingBottom: 50 }}>
        <p>TODO: Yearly revenue / monthly revenue / quarter revenue</p>
        <h1>Number of Insurance day per memeber</h1>
        {/* <DasDataSet columns={perDayColumns} rows={calculateTotalRevenuePerDay(pageData.entities)} onActionClick={handleOnClick} /> */}
      </Container>
      <Divider />
      <Container>
        <h1>Number of Insurance days per carrier per month</h1>
        {/* <DasDataSet columns={perDayColumns} rows={calculateTotalRevenuePerMonth(pageData.entities)} onActionClick={handleOnClick} /> */}
        {/* <DasDataSet columns={cols} rows={result} onActionClick={handleOnClick} /> */}
      </Container>
    </>
  );
}
