import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Button, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 400,
    textAlign: 'center',
  },
  keyField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'default',
  },
  copyButton: {
    marginLeft: theme.spacing(1),
  },
}));

type SaveKeyModalProps = {
  open: boolean;
  onClose: () => void;
  secretKey: string;
  permissions: string;
};

const SaveKeyModal = ({ open, onClose, secretKey, permissions }: SaveKeyModalProps) => {
  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(secretKey);
  };

  return (
    <Modal
      aria-labelledby="save-key-modal-title"
      aria-describedby="save-key-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant="h6">Save your key</Typography>
          <Typography variant="body2" gutterBottom style={{ textAlign: 'justify' }}>
            Please save this secret key somewhere safe and accessible. For security reasons, you won&apos;t be able to view it again through this
            account. If you lose this secret key, you&apos;ll need to generate a new one.
          </Typography>
          <TextField
            className={classes.keyField}
            variant="outlined"
            fullWidth
            value={secretKey}
            inputProps={{
              style: { cursor: 'default' },
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy}>
                    <FileCopyIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body1" gutterBottom style={{ textAlign: 'left', fontWeight: 600 }}>
            Permissions
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'left', fontWeight: 600 }}>
            {permissions === 'read' ? 'Read only API resource' : 'Read and write API resources'}
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose} style={{ marginTop: '16px' }}>
            Done
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default SaveKeyModal;
