import React, { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export type LangOptions = "en" | "he";
export type DirectionOptions = "ltr" | "rtl";
interface LanguageContext {
  lang: LangOptions;
  changeLang: (s: LangOptions) => void;
  dir: DirectionOptions;
}
type ProviderProps = { children: React.ReactNode | React.ReactNode[] };

export const Context = createContext<LanguageContext | null>(null);

export const Provider = ({ children }: ProviderProps) => {
  const [state, setState] = useState<{
    lang: LangOptions;
    dir: DirectionOptions;
  }>({
    lang: "en",
    dir: "ltr",
  });
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en" || lang === "he") {
      i18n.changeLanguage(lang);
      setState({ lang, dir: lang === "he" ? "rtl" : "ltr" });
    }
  }, []);

  return (
    <Context.Provider
      value={{
        lang: state.lang,
        dir: state.dir,
        changeLang: (lang: LangOptions) => {
          window.localStorage.removeItem("lang");
          window.localStorage.setItem("lang", lang);
          i18n.changeLanguage(lang);
          setState({ lang, dir: lang === "he" ? "rtl" : "ltr" });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(Context);
  if (context === null) {
    throw new Error("No provider for this context");
  }
  return context;
};
