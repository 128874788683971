import React from "react";
import Swal from "sweetalert2";
import "./App.scss";
import { Provider as AuthProvider } from "./contexts/authContext";
import { Provider as LangProvider } from "./contexts/langContext";
import HomePage from "./pages/HomePage";
import WelcomePage from "./pages/WelcomePage";

function App() {
  return (
    <LangProvider>
      <AuthProvider>
        <WelcomePage />
        <HomePage />
      </AuthProvider>
    </LangProvider>
  );
}
// for prod
export default App;
